import React, { Component } from "react";
import { Modal, Tabs } from "antd-min";
import { fmtMsg } from "@app/util/index";
import { connect } from "gl-commonui";
import { LinkManager as LinkManagerLocale } from "@app/locales/localeid";
import {
    fetchLinksFromServer,
    addLink,
    removeLink,
    editLink,
    setNewLinksValue,
    cancelNewLinksValue,
    ILink,
    setErrorValidate,
    clearAllData,
    setAddingClicked
} from "@app/states/link";
import { TabItem } from "./tab-item";
import { StateType } from "@app/states";
import { urlRegex, location, TabKeys, SubtabLocation, validURLRegex } from "./constants";

const { TabPane } = Tabs;

const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const SCHOOL_BUTTONS = [
    {
        id: "1",
        title: fmtMsg(LinkManagerLocale.TabSchoolResource),
        name: location.Resource
    },
    {
        id: "2",
        title: fmtMsg(LinkManagerLocale.TabSchoolAmin),
        name: location.Administrator
    }
];

const PARENT_BUTTONS = [
    {
        id: "4",
        title: fmtMsg(LinkManagerLocale.TabParentREP),
        name: location.Rep
    },
    {
        id: "6",
        title: fmtMsg(LinkManagerLocale.TabParentResources),
        name: location.Resources
    }
];

const CONTENT_BUTTONS = [
    {
        id: "7",
        title: fmtMsg(LinkManagerLocale.TabContentResources),
        name: location.Resource
    }
];

const TRAINING_BUTTONS = [
    {
        id: "9",
        title: fmtMsg(LinkManagerLocale.TabTrainingResource),
        name: location.Resource,
    },
];

const CONNECT_BUTTONS = [
    {
        id: "11",
        title: fmtMsg(LinkManagerLocale.TabConnectResource),
        name: location.Resource,
    },
];

const REPORT_BUTTONS = [
    {
        id: "13",
        title: fmtMsg(LinkManagerLocale.TabReportResource),
        name: location.Resource,
    },
];

const tabs = {
    school: "school",
    parent: "parent",
    content: "content",
    training: "training",
    connect: "connect",
    report: "report",
};

const KeyLocationMap: Map<string, string> = new Map([
    [TabKeys.School, SubtabLocation.School_Resource],          // activeKey: School (1), activeLocation: Resource (1)
    [TabKeys.Parent, SubtabLocation.Parent_REP],               // activeKey: Parent (2), activeLocation: REP (4)
    [TabKeys.Content, SubtabLocation.Content_Resource],        // activeKey: Content (3), activeLocation: Resource (7)
    [TabKeys.Training, SubtabLocation.Training_Resource],      // activeKey: Training (4), activeLocation: Resource (9)
    [TabKeys.Connect, SubtabLocation.Connect_Resource],        // activeKey: Connect(5), activeLocation: Resource(11)
    [TabKeys.Report, SubtabLocation.Report_Resource],          // activeKey: Report (6), activeLocation: Resource (13)
]);

const TABS = [
    {
        tab: tabs.school,
        key: "1",
        content: SCHOOL_BUTTONS
    },
    {
        tab: tabs.parent,
        key: "2",
        content: PARENT_BUTTONS
    },
    {
        tab: tabs.content,
        key: "3",
        content: CONTENT_BUTTONS
    },
    {
        tab: tabs.training,
        key: "4",
        content: TRAINING_BUTTONS
    },
    {
        tab: tabs.connect,
        key: "5",
        content: CONNECT_BUTTONS
    },
    {
        tab: tabs.report,
        key: "6",
        content: REPORT_BUTTONS
    },
];

interface ILinkManagerProps {
    regionId: string;
    toggleLinkManager: () => void;
    visible: boolean;
    fetchLinksFromServer: (d) => void;
    addLink: (d) => void;
    removeLink: (d) => void;
    editLink: (d) => void;
    setNewLinksValue: () => void;
    cancelNewLinksValue: () => void;
    setErrorValidate: (d) => void;
    isError: boolean;
    isLoading: boolean;
    linksSession: ILink[];
    isValidateError: boolean;
    isEdit: boolean;
    clearAllData: () => void;
    setAddingClicked: () => void;
    addingClicked: boolean;
    isReadOnly: boolean;
    onDelete: () => void;
}

interface ILinkManagerState {
    activeLocation: string;
    activeKey: string;
}

@connect(
    ({
        link: {
            isError,
            isLoading,
            linksSession,
            isValidateError,
            addingClicked
        }
    }: StateType) => {
        return {
            isError,
            isLoading,
            linksSession,
            isValidateError,
            addingClicked
        };
    },
    {
        fetchLinksFromServer,
        addLink,
        removeLink,
        editLink,
        setNewLinksValue,
        cancelNewLinksValue,
        setErrorValidate,
        clearAllData,
        setAddingClicked
    }
)
export class LinkManager extends Component<
    ILinkManagerProps,
    ILinkManagerState
> {
    state = {
        activeLocation: "1",
        activeKey: "1"
    };
    componentDidMount() {
        const {
            regionId,
            fetchLinksFromServer,
            isEdit,
            clearAllData
        } = this.props;
        if (!isEdit) {
            clearAllData();
            return;
        }
        fetchLinksFromServer({ regionId });
    }

    linkDataFiltered = (site: string) => {
        const { linksSession } = this.props;

        let data = null;
        switch (site) {
            case tabs.school:
                data = linksSession.filter(link => link.site === "school");
                break;
            case tabs.parent:
                data = linksSession.filter(link => link.site === "parent");
                break;
            case tabs.content:
                data = linksSession.filter(link => link.site === "content");
                break;
            case tabs.training:
                data = linksSession.filter(link => link.site === "training");
                break;
            case tabs.connect:
                data = linksSession.filter(link => link.site === "connect")
                break;
            case tabs.report:
                data = linksSession.filter(link => link.site === "report");
                break;
            default:
                break;
        }
        return data;
    };

    changeActiveLocation = (id: string) => {
        this.setState({ activeLocation: id });
    };

    onCancelLinkManager = () => {
        const { toggleLinkManager, cancelNewLinksValue } = this.props;
        cancelNewLinksValue();
        toggleLinkManager();
        this.setState({ activeKey: "1", activeLocation: "1" });
        setErrorValidate(false);
    };

    findFirstInvalidLink = (linksSession: ILink[]) => {
        return linksSession.find(({ englishText, localText, link }) => !englishText || !localText || !link || !urlRegex.test(link) || !validURLRegex.test(link)); 
    }

    onOkLinkManager = async () => {
        const { linksSession, isValidateError } = this.props;
        await this.props.setAddingClicked();

        const getLocationName = (buttons) => {
            return buttons.find(
                btn => btn.id === this.state.activeLocation
            ) && buttons.find(
                btn => btn.id === this.state.activeLocation
            ).name;
        }

        const currentLocationValid = linksSession
            .filter(
                ({ location, site }) =>
                    location === (getLocationName(SCHOOL_BUTTONS) || getLocationName(PARENT_BUTTONS) || getLocationName(CONTENT_BUTTONS) || getLocationName(TRAINING_BUTTONS) || getLocationName(CONNECT_BUTTONS) || getLocationName(REPORT_BUTTONS)) &&
                    site === TABS.find(tab => tab.key === this.state.activeKey).tab
            )
            .every(
                ({ englishText, localText, link, location, site }) =>
                    [englishText, localText, link].every(item => !!item) &&
                    urlRegex.test(link) && validURLRegex.test(link)
            );

        const firstLinkInvalid = this.findFirstInvalidLink(linksSession);

        const getNextLocation = (buttons) => {
            const nextLocationId = buttons.find(
                btn => btn.name === firstLinkInvalid.location
            ) &&
                buttons.find(
                    btn => btn.name === firstLinkInvalid.location
                ).id;

            return nextLocationId;
        }

        const nextLocationFinder = (site: string) => {
            switch (site) {
                case tabs.school:
                    return getNextLocation(SCHOOL_BUTTONS);
                case tabs.parent:
                    return getNextLocation(PARENT_BUTTONS);
                case tabs.content:
                    return getNextLocation(CONTENT_BUTTONS);
                case tabs.training:
                    return getNextLocation(TRAINING_BUTTONS);
                case tabs.connect:
                    return getNextLocation(CONNECT_BUTTONS);
                case tabs.report:
                    return getNextLocation(REPORT_BUTTONS);
                default:
                    return getNextLocation(SCHOOL_BUTTONS);
            }
        }

        if (currentLocationValid && firstLinkInvalid) {
            const nextLocation = nextLocationFinder(firstLinkInvalid.site);
            const nextKey = TABS.find(tab => tab.tab === firstLinkInvalid.site).key;

            await this.setState(
                {
                    activeKey: nextKey
                },
                () => {
                    this.setState({ activeLocation: nextLocation }, () => {
                        this.props.setAddingClicked();
                    });
                }
            );
        }
        const isValid = !firstLinkInvalid;
        const isValidURL = linksSession.every(({ link }) => {
            return urlRegex.test(link) && validURLRegex.test(link);
        });
        if (!isValid || !isValidURL || isValidateError) return;
        const { toggleLinkManager, setNewLinksValue } = this.props;
        setNewLinksValue();
        toggleLinkManager();
        this.setState({ activeKey: "1", activeLocation: "1" });
    };

    componentDidUpdate(_, prevState) {
        if (prevState.activeKey !== this.state.activeKey) {
            const activeLocation = KeyLocationMap.get(this.state.activeKey);
            return this.setState({ activeLocation: activeLocation })
        }
    }

    onDelete = (id: string) => {
        const { linksSession, setErrorValidate, isValidateError } = this.props;

        // Delete icon not associated with form, so an invalid item remains invalid even on row delete, hence revalidate on delete.
        if (isValidateError) {
            const isInvalid = !!(this.findFirstInvalidLink(linksSession.filter(l => l.id !== id)));
            setErrorValidate(isInvalid);
        }

        this.props.removeLink(id);
        this.props.onDelete(); // Since delete not associate with form, inform the parent about form change.
    }

    render() {
        const {
            regionId,
            visible,
            isLoading,
            isError,
            addLink,
            editLink,
            setErrorValidate,
            addingClicked,
            isReadOnly
        } = this.props;
        if (isLoading) return <div>{fmtMsg(LinkManagerLocale.Loading)}</div>;
        if (isError) return <div>{fmtMsg(LinkManagerLocale.Error)}</div>;
        return (
            <Modal
                centered
                visible={visible}
                okText={fmtMsg({ id: LinkManagerLocale.TextAdd })}
                onOk={this.onOkLinkManager}
                onCancel={this.onCancelLinkManager}
                className={"link-manager-component"}
                okButtonProps={{ disabled: isReadOnly }}
                cancelButtonProps={{ disabled: isReadOnly }}
            >
                <Tabs
                    onChange={activeKey => {
                        this.setState({ activeKey });
                    }}
                    activeKey={this.state.activeKey}
                >
                    {TABS.map(({ tab, key, content }) => (
                        <TabPane tab={capitalizeFirstLetter(tab)} key={key}>
                            <TabItem
                                linkData={this.linkDataFiltered(tab)}
                                regionId={regionId}
                                content={content}
                                activeSite={tab}
                                activeLocation={this.state.activeLocation}
                                changeActiveLocation={this.changeActiveLocation}
                                addLink={addLink}
                                removeLink={this.onDelete}
                                editLink={editLink}
                                setErrorValidate={setErrorValidate}
                                addingClicked={addingClicked}
                                visible={visible}
                                isReadOnly={isReadOnly}
                            />
                            <div
                                style={{
                                    paddingTop: 16,
                                    fontStyle: "italic"
                                }}
                            >
                                {fmtMsg({ id: LinkManagerLocale.Note })}
                            </div>
                        </TabPane>
                    ))}
                </Tabs>
            </Modal>
        );
    }
}
