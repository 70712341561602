import React from "react";
import { Table, Input, Popconfirm, Form, Icon } from "antd";
import { LinkManager as LinkManagerLocale } from "@app/locales/localeid";
import { fmtMsg } from "@app/util/index";
import { ILink } from "@app/states/link";
import { urlRegex, validURLRegex } from "./constants";
import "./link-manager.less";

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

interface IEditableCellProps {
    editable: boolean;
    dataIndex: string;
    title: string;
    record: any;
    index: any;
    handleSave: any;
    editingRecord: string;
    setErrorValidate: (d) => void;
    addingClicked: boolean;
    handleError: (d) => void;
}

class EditableCell extends React.Component<IEditableCellProps> {
    state = {
        editing: false
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.addingClicked !== this.props.addingClicked) {
            this.save();
        }
    }

    save = (e?: any) => {
        const { record, handleSave, setErrorValidate } = this.props;
        this.form.validateFields([this.props.dataIndex], (error, values) => {
            if (error) {
                setErrorValidate(true);
                this.toggleEdit();
                handleSave({ ...record, ...values });
                return;
            }
            setErrorValidate(false);
            handleSave({ ...record, ...values });
        });
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;
        const { editing } = this.state;
        const rules = [
            {
                required: true,
                message: `${title} ${fmtMsg(LinkManagerLocale.ValidRequire)}`
            }
        ];
        if (title === "Link") {
            rules.push({
                pattern: urlRegex && validURLRegex,
                message: fmtMsg(LinkManagerLocale.ValidURL)
            });
        }
        const isValidURL =
            dataIndex === "link" ? urlRegex.test(record[dataIndex]) && validURLRegex.test(record[dataIndex]) : true;
        return !isValidURL || !record[dataIndex] || editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    rules,
                    initialValue: record[dataIndex]
                })(
                    <Input
                        ref={node => (this.input = node)}
                        onPressEnter={this.save}
                        onBlur={this.save}
                        onFocus={() => {
                            if (editing) return;
                            this.toggleEdit();
                        }}
                    />
                )}
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" onClick={this.toggleEdit}>
                {children}
            </div>
        );
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            editingRecord,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>
                        {this.renderCell}
                    </EditableContext.Consumer>
                ) : (
                    children
                )}
            </td>
        );
    }
}

interface IEditableTableProps {
    data: ILink[];
    addingClicked: boolean;
    editLink: (d) => void;
    removeLink: (d) => void;
    setErrorValidate: (d) => void;
    isReadOnly: boolean;
}
export default class EditableTable extends React.Component<
    IEditableTableProps
> {
    constructor(props) {
        super(props);
        const editable = !props.isReadOnly;
        this.columns = [
            {
                title: fmtMsg(LinkManagerLocale.EnglishText),
                dataIndex: "englishText",
                editable,
                className: "column-required"
            },
            {
                title: fmtMsg(LinkManagerLocale.LocalText),
                dataIndex: "localText",
                editable,
                className: "column-required"
            },
            {
                title: fmtMsg(LinkManagerLocale.Link),
                dataIndex: "link",
                editable,
                className: "column-required"
            },
            {
                title: fmtMsg(LinkManagerLocale.IconDelete),
                dataIndex: "delete",
                render: (text, record) => {
                    if (editable) {
                        return this.props.data.length >= 1 ? (
                            <Popconfirm
                                title="Sure to delete?"
                                onConfirm={() => this.handleDelete(record.id)}
                            >
                                <div style={{ textAlign: "center" }}>
                                    <a>
                                        <Icon type="delete" />
                                    </a>
                                </div>
                            </Popconfirm>
                        ) : null;
                    }
                    return (
                        <div style={{ textAlign: "center" }}>
                            <a>
                                <Icon
                                    style={{
                                        color: "#d9d9d9",
                                        cursor: "not-allowed"
                                    }}
                                    type="delete"
                                />
                            </a>
                        </div>
                    );
                }
            }
        ];

        this.state = {
            dataSource: this.props.data,
            editingRecord: "",
            forceSave: false
        };
    }

    handleDelete = id => {
        this.props.removeLink(id);
    };

    handleSave = row => {
        this.props.editLink({ editedData: row, id: row.id });
    };

    render() {
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell
            }
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                    editingRecord: this.state.editingRecord,
                    setErrorValidate: this.props.setErrorValidate,
                    addingClicked: this.props.addingClicked,
                    handleError: this.handleError
                })
            };
        });
        return (
            <div className="link-manager-component__grid">
                <Table
                    components={components}
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={this.props.data}
                    columns={columns}
                    pagination={false}
                    rowKey="id"
                />
            </div>
        );
    }
}
