export const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export const validURLRegex = new RegExp('^(https?:\\/\\/)?'+ // protocol
                                        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                                        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                                        '(\\:\\d+)?' + // port
                                        '(\\/[-a-z\\d%_.~+@]*)*'+ // path with optional '@'
                                        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                                        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator


export const locations = {
    "1": "resource",
    "2": "administrator",
    "3": "helps",
    "4": "rep",
    "5": "helps",
    "6": "resources"
};

export enum site {
    School = "school",
    Parent = "parent",
    Content = "content",
    Training = "training",
    Connect = "connect",
    Report = "report",
}

export enum location {
    Administrator = "administrator",
    Resource = "resource",
    Helps = "helps",
    Rep = "rep",
    Resources = "resources",
    Training_Resource = "resource",
    Connect_Resource = "resource",
    Report_Resource = "resource",
}

export enum TabKeys {
    School = "1",
    Parent = "2",
    Content = "3",
    Training = "4",
    Connect = "5",
    Report = "6",
}

export enum SubtabLocation {
    School_Resource = "1",
    Parent_REP = "4",
    Content_Resource = "7",
    Training_Resource = "9",
    Connect_Resource = "11",
    Report_Resource = "13",
}