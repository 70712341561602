import React, { useEffect, useRef, useState } from "react";
import { Checkbox, Modal } from "antd-min";
import { EventCategory, EventInfo, fmtMsg } from "@app/util";
import { GSAdminLocale } from "@app/locales/localeid";
import "./event-manager.less";
import { connect, StateType } from "gl-commonui";
import { EventManagerSettingsModel } from "@app/service/admin/regions";
import { setEventSettings } from "@app/states/event-manager";
import { cloneDeep } from "lodash";

interface IEventManagerProps {
    toggleEventManager: () => void;
    visible: boolean;
    eventSettings: EventManagerSettingsModel[];
    hasEventSettingsChanged: boolean;
    setEventSettings: (d) => void;
    isReadOnly: boolean;
}


export const EventManager = connect(
    ({ eventManager: { eventSettings, hasEventSettingsChanged } }: StateType) => ({
        eventSettings,
        hasEventSettingsChanged
    }),
    {
        setEventSettings
    }
)((props: IEventManagerProps) => {
    const [currentEventSettings, setCurrentEventSettings] = useState<EventManagerSettingsModel[]>([]);

    useEffect(() => {
        const currentSettings = cloneDeep(props.eventSettings);
        setCurrentEventSettings(currentSettings);
    }, [])

    const onOkEventManger = () => {
        props.setEventSettings(currentEventSettings);
        props.toggleEventManager();
    };

    const onCancelEventManger = () => {
        props.toggleEventManager();
    };

    const EventSetting4Display: Map<EventInfo, string> = new Map([
        [EventInfo.PendingChangesAddCampus, GSAdminLocale.EventManagerAddCampusEvent],
        [EventInfo.PendingChangesAddClass, GSAdminLocale.EventManagerAddClassEvent],
        [EventInfo.PendingChangesChangeCampusMandatoryData, GSAdminLocale.EventManagerChangeCampusMandatoryDataEvent],
        [EventInfo.PendingChangesChangeClassMandatoryData, GSAdminLocale.EventManagerChangeClassMandatoryDataEvent],
        [EventInfo.PendingChangesChangeClassUnitPlan, GSAdminLocale.EventManagerChangeClassUnitPlanEvent],
        [EventInfo.PendingChangesChangeLicense, GSAdminLocale.EventManagerChangeLicenseEvent],
        [EventInfo.PendingChangesChangeSchoolMandatoryData, GSAdminLocale.EventManagerChangeSchoolMandatoryDataEvent],
        [EventInfo.PendingChangesDisableCampus, GSAdminLocale.EventManagerDisableCampusEvent],
        [EventInfo.PendingChangesDisableClass, GSAdminLocale.EventManagerDisableClassEvent],
        [EventInfo.PendingChangesEnableCampus, GSAdminLocale.EventManagerEnableCampusEvent],
        [EventInfo.PendingChangesEnableClass, GSAdminLocale.EventManagerEnableClassEvent],
        [EventInfo.PendingChangesMaterialOrder, GSAdminLocale.EventManagerMaterialOrderEvent],
        [EventInfo.PendingChangesMoveStudent, GSAdminLocale.EventManagerMoveStudentEvent],
        [EventInfo.PendingChangesMoveUnregisteredStudent, GSAdminLocale.EventManagerMoveUnregisteredStudentEvent],
        [EventInfo.PendingChangesPromoteClass, GSAdminLocale.EventManagerPromoteClassEvent],
        [EventInfo.PendingChangesRemoveStudent, GSAdminLocale.EventManagerRemoveStudentEvent],
        [EventInfo.StudentVerificationAllowStudentVerification, GSAdminLocale.EventManagerAllowStudentVerificationEvent],
        [EventInfo.PendingChangesMovePromoteStudents, GSAdminLocale.EventManagerMovePromoteStudentsEvent]
    ]);

    const onSettingsChange = (e, clickedEventId) => {
        var index = currentEventSettings.findIndex(x => x.id === clickedEventId);
        if (index > -1) {
            currentEventSettings[index].isEnabled = e.target.checked;
            let newData = currentEventSettings.map(x => x);
            setCurrentEventSettings(newData);
        }
    }
    const {isReadOnly} = props;
    return <Modal
        centered
        visible={props.visible}
        title={fmtMsg({ id: GSAdminLocale.EventManagerHeading })}
        okText={fmtMsg({ id: GSAdminLocale.EventManagerOkText })}
        onOk={onOkEventManger}
        onCancel={onCancelEventManger}
        className={"event-manager-component"}
        okButtonProps={{ disabled: isReadOnly }}
        cancelButtonProps={{ disabled: isReadOnly }}
    >
        <div className="settings-section">
            {currentEventSettings.some(setting => setting.eventCategoryId === EventCategory.School) && <div className="category-section">
                <div className="category-heading">{fmtMsg({ id: GSAdminLocale.EventManagerSchoolCategory })}</div>
                <div className="settings">
                    {currentEventSettings.filter(setting => setting.eventCategoryId === EventCategory.School).map(setting => {
                        return <div className="setting"> <Checkbox disabled={isReadOnly} key={setting.id + setting.eventCategoryId} checked={setting.isEnabled} onChange={(e) => onSettingsChange(e, setting.id)}>{fmtMsg({ id: EventSetting4Display.get(setting.id) })}</Checkbox></div>
                    })}
                </div>
            </div>}
            {currentEventSettings.some(setting => setting.eventCategoryId === EventCategory.Campus) &&
                <div className="category-section">
                    <div className="category-heading">{fmtMsg({ id: GSAdminLocale.EventManagerCampusCategory })}</div>
                    <div className="settings">
                        {currentEventSettings.filter(setting => setting.eventCategoryId === EventCategory.Campus).map(setting => {
                            return <div className="setting"> <Checkbox disabled={isReadOnly} key={setting.id + setting.eventCategoryId} checked={setting.isEnabled} onChange={(e) => onSettingsChange(e, setting.id)}>{fmtMsg({ id: EventSetting4Display.get(setting.id) })}</Checkbox></div>
                        })}
                    </div>
                </div>}
            {currentEventSettings.some(setting => setting.eventCategoryId === EventCategory.Class) &&
                <div className="category-section">
                    <div className="category-heading">{fmtMsg({ id: GSAdminLocale.EventManagerClassCategory })}</div>
                    <div className="settings">
                        {currentEventSettings.filter(setting => setting.eventCategoryId === EventCategory.Class).map(setting => {
                            return <div className="setting"> <Checkbox disabled={isReadOnly} key={setting.id + setting.eventCategoryId} checked={setting.isEnabled} onChange={(e) => onSettingsChange(e, setting.id)}>{fmtMsg({ id: EventSetting4Display.get(setting.id) })}</Checkbox></div>
                        })}
                    </div>
                </div>}
            {currentEventSettings.some(setting => setting.eventCategoryId === EventCategory.License) &&
                <div className="category-section">
                    <div className="category-heading">{fmtMsg({ id: GSAdminLocale.EventManagerLicenseCategory })}</div>
                    <div className="settings">
                        {currentEventSettings.filter(setting => setting.eventCategoryId === EventCategory.License).map(setting => {
                            return <div className="setting"> <Checkbox disabled={isReadOnly} key={setting.id + setting.eventCategoryId} checked={setting.isEnabled} onChange={(e) => onSettingsChange(e, setting.id)}>{fmtMsg({ id: EventSetting4Display.get(setting.id) })}</Checkbox></div>
                        })}
                    </div>
                </div>}
            {currentEventSettings.some(setting => setting.eventCategoryId === EventCategory.MaterialRequest) &&
                <div className="category-section">
                    <div className="category-heading">{fmtMsg({ id: GSAdminLocale.EventManagerMaterialRequestCategory })}</div>
                    <div className="settings">
                        {currentEventSettings.filter(setting => setting.eventCategoryId === EventCategory.MaterialRequest).map(setting => {
                            return <div className="setting"> <Checkbox disabled={isReadOnly} key={setting.id + setting.eventCategoryId} checked={setting.isEnabled} onChange={(e) => onSettingsChange(e, setting.id)}>{fmtMsg({ id: EventSetting4Display.get(setting.id) })}</Checkbox></div>
                        })}
                    </div>
                </div>
            }
            {currentEventSettings.some(setting => setting.eventCategoryId === EventCategory.StudentVerification) &&
                <div className="category-section">
                    <div className="category-heading">{fmtMsg({ id: GSAdminLocale.EventManagerStudentVerificationCategory })}</div>
                    <div className="settings">
                        {currentEventSettings.filter(setting => setting.eventCategoryId === EventCategory.StudentVerification).map(setting => {
                            return <div className="setting"> <Checkbox disabled={isReadOnly} key={setting.id + setting.eventCategoryId} checked={setting.isEnabled} onChange={(e) => onSettingsChange(e, setting.id)}>{fmtMsg({ id: EventSetting4Display.get(setting.id) })}</Checkbox></div>
                        })}
                    </div>
                </div>
            }
        </div>

    </Modal>;
});