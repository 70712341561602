import React from "react";
import { Button, Radio } from "antd-min";
import { LinkManager as LinkManagerLocale } from "@app/locales/localeid";
import { fmtMsg } from "@app/util/index";
import Table from "./table";
import { locations, site, location } from "./constants";
import "./link-manager.less";

interface ITabItemProps {
    regionId: string;
    content: any[];
    linkData: any[];
    activeLocation: string;
    activeSite: string;
    changeActiveLocation: (id: string) => void;
    addLink: (d) => void;
    removeLink: (d) => void;
    editLink: (d) => void;
    setErrorValidate: (d) => void;
    addingClicked: boolean;
    visible: boolean;
    isReadOnly: boolean;
}

export class TabItem extends React.Component<ITabItemProps, any> {
    displayContent = () => {
        const { content } = this.props;
        return (
            <>
                {content.map(({ id, title }) => {
                    return (
                        <Radio.Button
                            onClick={() => this.props.changeActiveLocation(id)}
                            value={id}
                            key={id}
                        >
                            {title}
                        </Radio.Button>
                    );
                })}
            </>
        );
    };

    linkDataFilter = () => {
        const { activeLocation, linkData } = this.props;
        let data = null;
        switch (activeLocation) {
            case "1":
                data = linkData.filter(
                    link =>
                        link.site === site.School && link.location === location.Resource
                );
                break;
            case "2":
                data = linkData.filter(
                    link =>
                        link.site === site.School &&
                        link.location === location.Administrator
                );
                break;
            case "3":
                data = linkData.filter(
                    link => link.site === site.School && link.location === location.Helps
                );
                break;
            case "4":
                data = linkData.filter(
                    link => link.site === site.Parent && link.location === location.Rep
                );
                break;
            case "5":
                data = linkData.filter(
                    link => link.site === site.Parent && link.location === location.Helps
                );
                break;
            case "6":
                data = linkData.filter(
                    link => link.site === site.Parent && link.location === location.Resources
                );
                break;
            case "7":
                data = linkData.filter(
                    link => link.site === site.Content && link.location === location.Resource
                );
                break;
            case "9":
                data = linkData.filter(
                    link => link.site === site.Training && link.location === location.Resource
                );
                break;
            case "10":
                data = linkData.filter(
                    link => link.site === site.Training && link.location === location.Administrator
                );
                break;
            case "11":
                data = linkData.filter(
                    link => link.site === site.Connect && link.location === location.Resource
                );
                break;
            case "12":
                data = linkData.filter(
                    link => link.site === site.Connect && link.location === location.Administrator
                );
                break;
            case "13":
                data = linkData.filter(
                    link => link.site === site.Report && link.location === location.Resource
                );
                break;
            case "14":
                data = linkData.filter(
                    link => link.site === site.Report && link.location === location.Administrator
                );
            default:
                break;
        }
        return data;
    };

    handleAdd = () => {
        const { addLink, activeLocation, activeSite } = this.props;
        let location = null;
        switch (activeLocation) {
            case "1":
                location = locations["1"];
                break;
            case "2":
                location = locations["2"];
                break;
            case "3":
                location = locations["3"];
                break;
            case "4":
                location = locations["4"];
                break;
            case "5":
                location = locations["5"];
                break;
            case "6":
                location = locations["6"];
                break;
            case "7":
                location = locations["1"];
                break;
            case "9":
                location = locations["1"];
                break;
            case "11":
                location = locations["1"];
                break;
            case "13":
                location = locations["1"];
                break;
            default:
                break;
        }

        addLink({ location, site: activeSite });
    };

    render() {
        const {
            activeLocation,
            removeLink,
            editLink,
            setErrorValidate,
            addingClicked,
            visible,
            isReadOnly
        } = this.props;
        return (
            <div className="tab-item">
                <Radio.Group value={activeLocation}>
                    <div
                        style={{
                            marginBottom: 16
                        }}
                    >
                        <div>{this.displayContent()}</div>
                    </div>
                </Radio.Group>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        marginBottom: 16
                    }}
                >
                    <span
                        style={{
                            display: "inline-block",
                            margin: "auto 0",
                            fontWeight: 500,
                            fontSize: 16
                        }}
                    >
                        {fmtMsg(LinkManagerLocale.Label)}
                    </span>

                    <div>
                        <Button
                            onClick={this.handleAdd}
                            type="primary"
                            style={{ margin: "auto 0" }}
                            disabled={isReadOnly}
                        >
                            {fmtMsg(LinkManagerLocale.AddALink)}
                        </Button>
                    </div>
                </div>
                {visible ? (
                    <Table
                        data={this.linkDataFilter()}
                        removeLink={removeLink}
                        editLink={editLink}
                        setErrorValidate={setErrorValidate}
                        addingClicked={addingClicked}
                        isReadOnly={isReadOnly}
                    />
                ) : null}
            </div>
        );
    }
}
